<template>
  <div>
    <b-col cols="12" class="mb-2">
      <b-button variant="primary">新建PIA流程</b-button>
    </b-col>

    <b-col xl="3" md="2">
      <b-card v-for="(item, index) in queryData" :key="index" no-body>
        <b-card-header>
          <b-media vertical-align="top">
            <template #aside>
              <b-badge variant="light-primary" class="mr-1">
                <span>{{ stringToDate(item.start) }}</span>
                <feather-icon icon="ArrowRightIcon" class="mx-1" />
                <span>{{ stringToDate(item.end) }}</span>
              </b-badge>
            </template>
          </b-media>
          <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
        </b-card-header>

        <b-card-body>
          <div>   
            <h4>{{ item.title }}</h4>            
            <b-card-text>发起： {{ item.ownerName }} </b-card-text>
            <br/><br/>
            <h5>简介： {{ item.summary }}</h5>
            <h5>
              <b-badge variant="light-secondary" class="mr-1" size="sm">{{
                item.issueDate
              }}</b-badge>
              {{ item.issueNo }}
            </h5>
          </div>
          <div>{{ item.abstract }}</div>
        </b-card-body>

        <b-card-footer class="text-right">
          <!-- <b-button
            variant="outline-primary"
            size="sm"
            @click="preview(item)"
            class="mr-1"
          >
            <feather-icon icon="EditIcon" /> 查看</b-button
          > -->

          <b-button
            variant="outline-danger"
            size="sm"
            :disabled="item.eid == 1"
            @click="onEdit(item)"
          >
            <feather-icon icon="EditIcon" /> 编辑</b-button
          >
        </b-card-footer>
      </b-card>
    </b-col>

    <!-- pagination -->
    <b-col cols="12" class="mt-1">
      <b-pagination
        v-if="queryData"
        v-model="listTableCurrentPage"
        :total-rows="listTableTotalRows"
        :per-page="listTablePerPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardFooter,
  BMedia,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BPagination,
  BBadge,
  BIconShieldLock,
} from "bootstrap-vue";
import FormWizardNumber from "./FormWizardNumber.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  name: "PiaProcessList",

  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BPagination,
    BBadge,
    BIconShieldLock,
    FeatherIcon,
  },

  data() {
    return {
      queryData: [],
      listTableCurrentPage: 0,
      listTableTotalRows: 0,
      listTablePerPage: 10,
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.$http.get("/pias").then((response) => {
        this.queryData = response.data.data.list;
        this.listTableTotalRows = response.data.data.total;
      });
    },

    stringToDate(sDate) {
      let date = new Date(sDate);
      return date.toISOString().split("T")[0];
    },

    onEdit(item){
      this.$router.push(`/pia-process/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
